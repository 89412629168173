import React, {Component, useState, useEffect} from 'react';
import {Container, Table} from 'react-bootstrap'; 
import {Helmet} from 'react-helmet';
import {goToTop} from 'react-scrollable-anchor';

import './Test.css';
import { getDatabase, ref, onValue} from "firebase/database";

import { data } from '../movieList.js';


class Test extends Component {

	constructor() {
		super();
		this.state = {
			tableData: []
		}
		
	}
	componentDidMount() {
        goToTop();
		

		let records = [];
		for (let i = 0; i < data.length; i++) records.push({"key": i, "data": data[i]});
		this.setState({tableData: records});
		
		
    }
	
    render() {
        return (
			<div>
				<Helmet>
					<title>Test - Rehan Hajee</title>
				</Helmet>
				<Container className="test">
					<main>
						<section>
							<h1 className="title">Movie List</h1>
							
							Select Language:
							{/*<select ng-model="language">*/}
							<select id="language">
								<option value="English and Hindi">English and Hindi</option>
								<option value="English">English</option>
								<option value="Hindi">Hindi</option>
							</select>
							<br /><br />
							{/*<input type="text" id="mySearchInput" onkeyup="filter('mySearchInput',0)" placeholder="Search for a movie name.." />*/}
							<input type="search" id="mySearchInput" placeholder="Search for a movie name..."  />
							&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
							Enter a Year:
							{/*<input type="text" id="myYearInput" onkeyup="filter('myYearInput', 1)" />*/}
							<input type="search" id="myYearInput" />
							<br /><br />
						</section>
						<section>
							<article>
								
								<Table className="myTable" striped>
									<thead>
										<tr>
											<th>Name</th>
											<th>Year</th>
											<th>Location</th>
										</tr>
									</thead>
									<tbody>
										{this.state.tableData.sort((a, b) => 
											b.data.Year - (a.data.Year) || a.data.Name.localeCompare(b.data.Name)
										).map((row, index) => {
											return(
												<tr key={index}>
													<td>{row.data.Name}</td>
													<td align="center">{row.data.Year}</td>
													<td>{row.data.Location}</td>
												</tr>
											)
										})}
									
									</tbody>
								</Table>

								<br />
							</article>
						</section>
					</main>
				</Container>
			</div>
        );
	}
}

export default Test;
